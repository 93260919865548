<template>
    <div class="wrapper">

        <van-nav-bar title="个人中心" @click-left="goHome">
            <template #left>
                <van-icon name="wap-home" size="18"/>
            </template>
        </van-nav-bar>

        <div class="header">
            <div class="user_info">
                <div class="avatar">
                    <img width="48" height="48" :src="require('../../assets/img/avatar.png')"/>
                </div>
                <div class="user_misc">
                    <div class="user_name">{{ phone }}</div>
                    <div class="reg_date">注册日期：{{ dataList.createTime }}</div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="main-content">
                <div class="user-center">
                    <van-cell-group title="基本信息" v-if="flag">
                        <van-cell title="我的报名" is-link @click="goMyApplication"/>
                        <van-cell title="消息中心" is-link @click="goMessageCenter"/>
                        <van-cell title="修改密码" is-link @click="goEditPassword"/>
                    </van-cell-group>
                    <van-cell-group title="其它相关">
                        <van-cell title="帮助中心" is-link @click="goHelpCenter"/>
                    </van-cell-group>
                    <van-button type="danger" @click="logOut" block>退出登录</van-button>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="copyright">Copyright &copy; {{ (new Date).getFullYear() }} 节点速聘（西安）人力资源服务有限公司</div>
        </div>
    </div>
</template>

<script>
import {getCountOfUnreadMessage, getClientUser} from "../../api/client-api";
import {Dialog} from "vant";

export default {
    data() {
        return {
            flag: false,
            active: 'my',
            messageCounts: "",
            phone: null,
            projectCode: null,
            dataList: []
        }
    },
    methods: {
        goHome() {
            this.$router.push({name: 'project', params: {id: this.projectCode}});
        },
        logOut() {
            Dialog.confirm({
                title: "确认退出？"
            }).then(()=>{
                sessionStorage.removeItem('un');
                sessionStorage.removeItem('Authorization');
                this.$router.push({name: 'project', params: {id: this.projectCode}});
            }).catch(()=>{
            })
        },
        goEditPassword() {
            this.$router.push({name: 'editPassword', query: {projectCode: this.projectCode}});
        },
        goHelpCenter() {
            this.$router.push({name: 'helpCenter', query: {projectCode: this.projectCode}});
        },
        goMessageCenter() {
            this.$router.push({name: 'messageCenter', query: {projectCode: this.projectCode}});
        },
        goMyApplication() {
            this.$router.push({name: 'myApplication', query: {projectCode: this.projectCode}});
        },
        goUserCenter() {
            this.$router.push({name: 'userCenter', query: {projectCode: this.projectCode}});
        },
        getUserOne() {
            if (sessionStorage.getItem('Authorization')) {
                getClientUser().then((res) => {
                    if (res.data.code === 1) {
                        this.dataList = res.data.data
                        this.dataList.createTime = this.dataList.createTime.substring(0, 10)
                    }
                })
            } else {
                let redirectTo = window.location.href;
                sessionStorage.setItem('redirectTo', redirectTo);
                this.$router.push({name: 'login', query: {projectCode: this.projectCode}});
            }
        },
    },
    mounted() {
        this.flag = sessionStorage.getItem('Authorization');
        this.phone = sessionStorage.getItem('un');
        this.projectCode = this.$route.query.projectCode;
        this.getUserOne()
    }
}
</script>

<style scoped>

</style>

