<template>
    <div class="wrapper tab_bar">
        <div class="header">
            <div class="user_info" slot="title">
                <div class="avatar">
                    <img width="48" height="48" :src="require('../../assets/img/avatar.png')"/>
                </div>
                <div class="name_department">
                    <div class="user_name">{{ userInfo.name }}</div>
                    <div class="department">{{ userInfo.mobile }}</div>
                </div>
            </div>
        </div>
        <div class="content">
            <van-cell-group title="基本信息">
                <van-cell title="修改密码" is-link url="/editPassword"/>
            </van-cell-group>
            <van-cell-group title="其它相关">
                <van-cell title="帮助中心" is-link url="/helpCenter"/>
            </van-cell-group>

            <div class="footer">
                <div class="copyright">版权所有 &copy; {{(new Date).getFullYear()}} 由 V聘360 提供支持</div>
            </div>

        </div>
        <div class="footer">
            <van-tabbar>
                <van-tabbar-item icon="wap-home" @click="goHome">首页</van-tabbar-item>
            </van-tabbar>
        </div>
    </div>
</template>

<script>
import {reqUserExternalOne} from '../../api/user-api';

export default {
    data() {
        return {
            userInfo: {
                avatar: '',
            }
        }
    },
    methods: {
        goHome() {
            this.$router.push({path: "/home"});
        },
        //查询用户信息
        getUserOne() {
            reqUserExternalOne().then(res => {
                if (res.data.code === 1) {
                    this.userInfo = res.data.data;
                } else {
                    this.$notify({type: 'danger', duration: '1000', message: "查询失败"});
                }
            }).catch(() => {
                this.$notify({type: 'danger', duration: '1000', message: "查询失败"});
            })
        }
    },
    mounted() {
        this.getUserOne();
    }
}
</script>

<style scoped>

</style>
